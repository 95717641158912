import { AccountManager } from '@core/core.types';

export class SetAccountManagersAction {
  static type = '[AccountManagers] SetAccountManagersAction';
  constructor(public accountManagers: AccountManager[]) {}
}

export class LoadAccountManagersAction {
  static type = '[AccountManagers] LoadAccountManagersAction';
}

export class LoadAccountManagerAction {
  static type = '[AccountManagers] LoadAccountManagerAction';
  constructor(public id: string) {}
}

export class GenerateAccountManagerAction {
  static type = '[AccountManagers] GenerateAccountManagerAction';
  constructor(public accountManager: AccountManager) {}
}

export class UpdateAccountManagerAction {
  static type = '[AccountManagers] UpdateAccountManagerAction';
  constructor(public accountManager: AccountManager) {}
}

export class ActivateAccountManagerAction {
  static type = '[AccountManagers] ActivateAccountManagerAction';
  constructor(public id: string) {}
}

export class DeactivateAccountManagerAction {
  static type = '[AccountManagers] DeactivateAccountManagerAction';
  constructor(public id: string) {}
}

export class AssignCountryToAccountManagerAction {
  static type = '[AccountManagers] AssignCountryToAccountManagerAction';
  constructor(public id: string, public countryName: string) {}
}

export class UnassignCountryFromAccountManagerAction {
  static type = '[AccountManagers] UnassignCountryFromAccountManagerAction';
  constructor(public id: string, public countryName: string) {}
}
